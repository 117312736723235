<template>
  <div class="h-full flex flex-col data-page">
    <PageTitle class="mb-2">
      {{ $t('Company settings') }}
    </PageTitle>
    <BaseFormCard
      :submit-text="$t('Update')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection :title="$t('Company details')">
        <FormKit
          v-model="model[SettingKeys.CompanyName]"
          name="company_name"
          type="text"
          :label="t('Company Name')"
          :placeholder="t('My Company')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyAdmin]"
          name="company_name"
          type="text"
          :label="t('Administrator')"
          :placeholder="t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyEmail]"
          name="company_email"
          type="text"
          :label="t('Contact Email')"
          :placeholder="t('hello@company.com')"
          validation="email"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyRegNumber]"
          name="company_registration_number"
          type="text"
          :label="t('Register No.')"
          :placeholder="t('J12/1234/2018')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyIdentificationNumber]"
          name="company_identification_number"
          type="text"
          :label="t('VAT Number')"
          :placeholder="t('RO32392318')"
          outer-class="col-span-6 md:col-span-2"
        />
        <div class="col-span-6 md:col-span-2" />

        <FormKit
          v-model="model[SettingKeys.CompanyStreet]"
          name="company_street_address"
          type="text"
          :label="t('Company street address')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model[SettingKeys.CompanyCity]"
          name="company_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model[SettingKeys.CompanyState]"
          name="company_county"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection :title="$t('Settings')">
        <FormKit
          v-model="model[SettingKeys.ShowTimesheetDates]"
          type="checkbox"
          :label="t('Show time sheet dates')"
          :help="t('When enabled, it will display when users logged their time in the reports.')"
          outer-class="col-span-6 mt-2"
        />

        <FormKit
          v-model="model[SettingKeys.IncludeHolidayWeekends]"
          type="checkbox"
          :label="t('Include weekends in holiday calculation')"
          :help="t('When enabled, the weekends will be included in the holiday calculation.')"
          outer-class="col-span-6 mt-6"
        />
      </FormCardSection>
      <FormCardSection
        v-if="hasAIAccess"
        :title="$t('Growee Assistant Context')">
        <FormKit
          id="general_information"
          v-model="aiModel.general_information"
          type="html"
          :label="t('General company information')"
          :placeholder="$t('Details such as company vision, mission, values etc.')"
          :help="t('This context information will be used as a base context for the AI models. This information will be accessible to all roles. We recommend providing general details such as company mission, vision, values etc')"
          validation="length:0,4000"
          validation-visibility="dirty"
          outer-class="col-span-6 mt-2"
        />

        <FormKit
          id="sensitive_information"
          v-model="aiModel.sensitive_information"
          type="html"
          :label="t('Detailed company information')"
          :placeholder="$t('Details such as company policies, procedures, etc.')"
          :help="t('This context information will be used as a base context for the AI models. This information will be accessible to only for admins, managers and HR roles. We recommend providing more detailed information such as company policies, procedures, etc')"
          validation="length:0,4000"
          validation-visibility="dirty"
          outer-class="col-span-6 mt-6"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageBilling')"
        :title="$t('Delete company account')"
      >
        <div class="col-span-6 mt-4">
          <BaseButton
            type="button"
            outline
            @click="onDeleteAccount"
          >
            {{ t('Delete company account') }}
          </BaseButton>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import axios from "axios"
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettings } from "@/modules/settings/composables/useSettings"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useAuthStore } from "@/modules/auth/store/authStore"

const { t } = useI18n()

const { model, loading, updateSettings } = useSettings([
  SettingKeys.ShowTimesheetDates,
  SettingKeys.IncludeHolidayWeekends,
  SettingKeys.CompanyName,
  SettingKeys.CompanyIdentificationNumber,
  SettingKeys.CompanyRegNumber,
  SettingKeys.CompanyStreet,
  SettingKeys.CompanyCity,
  SettingKeys.CompanyState,
  SettingKeys.CompanyAdmin,
  SettingKeys.CompanyEmail,
])

const aiModel = ref({
  general_information: '',
  sensitive_information: '',
})

const initialAIModel = ref({ ...aiModel.value })
const hasAIAccess = ref(false)

const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const router = useRouter()

async function onDeleteAccount() {
  const confirmed = await $confirm({
    title: t('Delete company account!'),
    description: t(`We will send a confirmation code via email which you will need to enter to delete the account. Are you sure you want to delete the account?`),
    buttonText: t('Delete'),
  })
  if (!confirmed) {
    return
  }
  await axios.post(`/tenant/send-delete-code`)
  await $confirm({
    title: t('Delete company account!'),
    description: t(`Delete company account description`),
    buttonText: t('Delete'),
    hasInput: true,
    inputType: 'otp',
    inputLabel: t('Enter confirmation code sent via email'),
    onResolve: async (data: any) => {
      const code = data.extraInputText
      await settingsStore.deleteTenant({
        code,
      })
      authStore.logout()
      await router.push('/login')
    },
  })
}

async function initAIModel() {
  const { data } = await axios.get('/restify/tenants/getters/tenant-information')
  aiModel.value.general_information = data?.general_information || ''
  aiModel.value.sensitive_information = data?.sensitive_information || ''
  hasAIAccess.value = data?.has_ai_access || false
  initialAIModel.value = { ...aiModel.value }
}

async function onSubmit() {
  await updateSettings()
  if (JSON.stringify(initialAIModel.value) === JSON.stringify(aiModel.value)) {
    return
  }
  try {
    loading.value = true
    await axios.post(`/restify/tenants/actions?action=update-tenant`, {
      general_information: aiModel.value.general_information,
      sensitive_information: aiModel.value.sensitive_information,
    })
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  initAIModel()
})
</script>

<route lang="yaml">
name: Company Settings
meta:
permission: manageCompany
</route>
