import get from 'lodash/get'
import { useBillingStore } from "@/modules/settings/store/billingStore";
const env = import.meta.env.VITE_ENV

export function isExceptionDomain(domain = '') {
  return ['', 'app', 'growee', 'growee-staging', 'www'].includes(domain)
}
/**
 * When running locally you can change this to change the default organization for testing purposes, but please don't commit the change
 */
const localStorageOrganization = localStorage.getItem('default-organization')
const defaultOrganization = localStorageOrganization || 'demo'
localStorage.setItem('default-organization', defaultOrganization)

/**
 * When running locally you can change this host to test with another api, but please don't commit the change
 * You can change this at runtime after running npm run serve
 */
const localHost = import.meta.env.VITE_LOCAL_API_URL || 'growee-staging.net'
const stagingHost = 'growee.net'
const productionHost = 'growee.net'
const productionHost2 = 'growee.ro'

const isProduction = env === 'production' && (window.location.host.includes(productionHost) || window.location.host.includes(productionHost2))
const isStaging = env === 'staging' && window.location.host.includes(stagingHost)

const envHosts: {[key: string]: string} = {
  local: localHost,
  staging: stagingHost,
  production: productionHost,
}
const host = envHosts[env] || envHosts.staging

let rootApiUrl = `https://api.${host}/api`
let rootApiBase = `https://api.${host}`
let apiHost = `https://{organization}.api.${host}/api`

if (import.meta.env.VITE_IS_LOCAL_API) {
  rootApiUrl = rootApiUrl.replace('api.', '')
  rootApiBase = rootApiUrl.replace('api.', '')
  apiHost = apiHost.replace('api.', '')
}
function replaceOrganizationInHost(organizationName: string | undefined) {
  if (isExceptionDomain(organizationName)) {
    apiHost = apiHost.replace('{organization}.', '')
    return
  }

  apiHost = apiHost.replace('{organization}', organizationName || '')
}

function getHostQuery() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('host')
}

export function getApiHost() {
  return API_URL
}

export function isRootApi() {
  return window.location.host === host
}

function isLocalEnv() {
  return window.location.host.startsWith('localhost')
}

function isDeployPreview() {
  return window.location.host.endsWith('netlify.app')
}
export function getTenantHost() {
  if (isLocalEnv() || isDeployPreview()) {
    const organization = getHostQuery() || defaultOrganization
    return `${organization}.${host}`
  }

  return window.location.host
}


let organizationName: string | undefined = ''
if (isStaging || isProduction) {
  organizationName = get(window.location.host.split('.'), '[0]')
  replaceOrganizationInHost(organizationName)
} else {
  const hostQuery = getHostQuery()
  organizationName = hostQuery || defaultOrganization

  replaceOrganizationInHost(organizationName)
}

export function isRootDomain() {
  return organizationName === '' || organizationName === 'www' || organizationName === 'app' || organizationName === 'growee' || organizationName === 'growee-staging'
}

export function isBetaTestingCompany() {
  const billingStore = useBillingStore()
  return ['binarcode', 'ac', 'demo'].includes(organizationName || '') || billingStore.isPaidPlan
}

export function isSpecialCompany() {
  return ['binarcode', 'ac', 'demo'].includes(organizationName || '')
}

export const API_URL = apiHost
export const API_BASE_URL = apiHost.replace('/api', '')
export const ROOT_API_URL = rootApiUrl
export const ROOT_HOST = `https://app.${host}`
export const ROOT_API_BASE = rootApiBase
export const ORGANIZATION_NAME = organizationName
export const DEFAULT_APP_TITLE = 'Growee'
