<template>
  <div class="flex flex-col">
    <BaseContentCard class="flex flex-col items-center time-logs flex-1 my-4">
      <div class="lg:mt-4">
        <PastTimerAlert
          v-if="viewType === timeViewTypes.Daily"
          :employee-id="userEmployeeId as string"
          @changeDay="onSelectedDayChange"
        />
        <WarningAlert v-if="selectedEmployee !== userEmployeeId" class="mb-4 ml-20">
          <div>
            <div class="font-semibold">
              {{ t('Employee timesheet', { name: employeeName }) }}
            </div>
            <div class="text-sm text-gray-600 -mt-1">
              <span>{{ t('Changes will save to employee timesheet', { name: employeeName }) }}</span>
              <button
                class="btn btn-link btn-xs !py-0"
                @click="goToYourTimesheet"
              >
                {{ $t('Resume editing your own timesheet') }}
              </button>
            </div>
          </div>
        </WarningAlert>
        <div class="flex items-start space-x-2">
          <div class="hidden lg:flex">
            <TrackTimeButton
              v-if="viewType === timeViewTypes.Daily"
              @click="onAdd"
            />
          </div>
          <div>
            <div class="flex flex-wrap items-center mb-6">
              <ArrowButtons
                @left-click="prevArrow"
                @right-click="nextArrow"
              />
              <h4 class="text-2xl font-semibold flex items-center space-x-2 ml-4 capitalize">
                <span v-if="isTodaySelected && viewType === timeViewTypes.Daily" class="font-semibold">
                  {{ $t('Today:') }}
                </span>
                <span v-if="isThisWeekSelected && viewType === timeViewTypes.Weekly" class="font-semibold">
                  {{ $t('This week:') }}
                </span>
                <span v-if="viewType === timeViewTypes.Daily">
                  {{ formattedSelectedDate }}
                </span>
                <span v-if="viewType === timeViewTypes.Weekly">
                  {{ formattedSelectedWeek }}
                </span>
                <BaseButton
                  v-if="!isTodaySelected && viewType === timeViewTypes.Daily"
                  variant="link"
                  size="xs"
                  @click="goToToday"
                >
                  {{ $t('Return to today') }}
                </BaseButton>
                <BaseButton
                  v-if="!isThisWeekSelected && viewType === timeViewTypes.Weekly"
                  variant="link"
                  size="xs"
                  @click="goToToday"
                >
                  {{ $t('Return to this week') }}
                </BaseButton>
              </h4>
              <div class="flex-1 mt-2 lg:mt-0 flex lg:justify-end">
                <div class="flex items-center space-x-2">
                  <FormKit
                    v-model="selectedDay"
                    type="customDatePicker"
                    :clearable="false"
                    :prefix-icon="CalendarIcon"
                    outer-class="date-picker cursor-pointer"
                    @update:model-value="onSelectedDayChange"
                  />

                  <DailyWeeklySwitch v-model="viewType" />

                  <FormKit
                    v-if="$can('manageEmployees')"
                    v-model="selectedEmployee"
                    type="employeeSelect"
                    outer-class="employee-select"
                    @update:model-value="onEmployeeChange"
                  />
                </div>
              </div>
            </div>
            <template v-if="viewType === timeViewTypes.Daily">
              <div class="lg:hidden items-center my-2">
                <TrackTimeButton
                  class="btn-sm px-0 lg:mx-2"
                  @click="showCreateDialog = true"
                />
              </div>
              <div>
                <div class="relative flex border-b border-gray-300 overflow-x-auto max-w-[80vw]">
                  <div
                    v-for="day in weekdays"
                    :key="day.value"
                    class="flex day-column flex-col capitalize text-gray-500 cursor-pointer pb-2 px-3 border-b-2"
                    :class="{
                      'border-primary font-medium text-gray-700': isSelected(day.value),
                      'border-transparent': !isSelected(day.value),
                    }"
                    @click="selectDay(day.value)"
                  >
                    <span>
                      {{ day.label }}
                    </span>
                    <div class="text-sm flex items-center space-x-2">
                      <span>{{ minutesToHours(getTotalMinutesForDay(timeLogs, day.value)) }}</span>
                      <BaseTooltip
                        v-if="hasRunningTimers(timeLogs, day.value)"
                        :content="$t('You have a timer running')"
                      >
                        <ClockAnimation
                          :running="true"
                          class="w-4 h-4"
                        />
                      </BaseTooltip>
                    </div>
                  </div>
                  <div class="flex flex-col items-end min-w-[150px] pb-2 text-gray-500">
                    <span>{{ $t('Week total') }}</span>
                    <span class="text-sm">{{ minutesToHours(totalMinutesForWeek) }}</span>
                  </div>
                </div>
                <DailyTimeSheet
                  :selected-employee="selectedEmployee as string"
                  :selected-day="selectedDay as string"
                  :time-sheets="timeLogsForSelectedDay"
                  :loading="loading"
                  @edit="onEditTime"
                  @refresh="getTimeSheets"
                />
              </div>
            </template>
            <WeeklyTimeView
              v-else-if="viewType === timeViewTypes.Weekly"
              v-model:from="from"
              v-model:to="to"
              :time-logs="timeLogs"
              :loading="loading"
              :employee-id="selectedEmployee as string"
            />
          </div>
        </div>
        <TimeSheetForm
          v-if="showCreateDialog"
          v-model="showCreateDialog"
          :is-dialog="true"
          :employee-id="selectedEmployee as string"
          :date="selectedDay as string"
          :other-logs="timeLogsForSelectedDay"
          @close="showCreateDialog = false"
          @cancel="showCreateDialog = false"
          @save="onCreateTimeSheet"
        />

        <TimeSheetForm
          v-if="showEditDialog"
          v-model="showEditDialog"
          :is-dialog="true"
          :is-edit="true"
          :data="timeToEdit"
          :employee-id="selectedEmployee as string"
          :date="selectedDay as string"
          @close="showEditDialog = false"
          @cancel="showEditDialog = false"
          @save="onEditTimeSheet"
          @delete="onDeleteTime"
        />
      </div>
    </BaseContentCard>
  </div>
</template>

<script lang="ts" setup>
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '@heroicons/vue/24/outline'
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay, isSameMonth,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns'
import { startCase, sumBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { onKeyStroke, useStorage } from "@vueuse/core"
import { getTimeSheetsForDay, getTotalMinutesForDay, hasRunningTimers } from '../../util/timeUtils'
import { formatDate, removeTimezoneFromDate } from '@/modules/common/utils/dateUtils'
import { useAuth } from '@/modules/auth/composables/useAuth'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import TimeSheetForm from '@/modules/time/components/TimeSheetForm.vue'
import TrackTimeButton from '@/modules/projects/components/TrackTimeButton.vue'
import { minutesToHours } from '@/modules/common/utils/parseHours'
import DailyTimeSheet from '@/modules/time/components/DailyTimeSheet.vue'
import BaseContentCard from '@/components/common/BaseContentCard.vue'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import Data = API.Data
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import ArrowButtons from '@/components/common/ArrowButtons.vue'
import { useTimeSheetStore } from "@/modules/time/store/timeSheetStore"
import ClockAnimation from "@/modules/time/components/ClockAnimation.vue"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import PastTimerAlert from "@/modules/time/components/PastTimerAlert.vue"
import DailyWeeklySwitch from "@/modules/time/components/DailyWeeklySwitch.vue"
import { timeViewTypes } from "@/modules/time/enum/timeEnums"
import WeeklyTimeView from "@/modules/time/components/WeeklyTimeView.vue"

const { t } = useI18n()
const timeLogs = ref<Data<Timesheet>[]>([])
const loading = ref(false)
const selectedDay = ref(getStartOfToday())
const from = ref(startOfWeek(new Date(), { weekStartsOn: 1 }))
const to = ref(endOfWeek(new Date(), { weekStartsOn: 1 }))

const showCreateDialog = ref(false)

const viewType = ref(timeViewTypes.Daily)

const projectStore = useProjectStore()
const employeeStore = useEmployeeStore()
const timeSheetStore = useTimeSheetStore()

function onAdd() {
  showCreateDialog.value = true
}

const daysOfWeek = computed(() => {
  return eachDayOfInterval({
    start: from.value,
    end: to.value,
  })
})

const weekdays = computed(() => {
  return daysOfWeek.value.map((day) => {
    return {
      label: formatDate(day, 'EEE'),
      value: day,
    }
  })
})

const weekRange = computed(() => {
  return {
    from,
    to,
  }
})

const formattedSelectedDate = computed(() => {
  return startCase(formatDate(selectedDay.value, 'EEEE, dd MMM'))
})

const formattedSelectedWeek = computed(() => {
  let start = formatDate(from.value, 'dd MMM')
  if (isSameMonth(from.value, to.value)) {
    start = formatDate(from.value, 'dd')
  }
  const end = startCase(formatDate(to.value, 'dd MMM'))
  return `${start} - ${end}`
})

const timeLogsForSelectedDay = computed(() => {
  return getTimeSheetsForDay(timeLogs.value, selectedDay.value)
})

const totalMinutesForWeek = computed(() => {
  return sumBy(timeLogs.value, 'attributes.worked_minutes')
})

const timeToEdit = ref<Data<Timesheet>>()
const showEditDialog = ref(false)

onKeyStroke('+', (e) => {
  if (!showCreateDialog.value && !showEditDialog.value) {
    onAdd()
  }
})

function onEditTime(time: Data<Timesheet>) {
  timeToEdit.value = time
  showEditDialog.value = true
}

function onDeleteTime(time: Data<Timesheet>) {
  showEditDialog.value = false
  timeLogs.value = timeLogs.value.filter(t => t.id !== time.id)
}

function getStartOfToday() {
  return removeTimezoneFromDate(startOfDay(new Date()))
}
function goToToday() {
  selectedDay.value = getStartOfToday()
}

function selectDay(day: Date) {
  selectedDay.value = removeTimezoneFromDate(day)
}

function isSelected(day: Date) {
  return isSameDay(day, selectedDay.value)
}

const isTodaySelected = computed(() => {
  return isSameDay(selectedDay.value, new Date())
})

const isThisWeekSelected = computed(() => {
  const start = startOfWeek(new Date(), { weekStartsOn: 1 })
  return isSameDay(from.value, start)
})

async function onEmployeeChange(employeeId: string) {
  await getTimeSheets(employeeId)
}
async function onSelectedDayChange(date: Date) {
  const newStart = startOfWeek(date, { weekStartsOn: 1 })
  const newEnd = endOfWeek(date, { weekStartsOn: 1 })

  if (!isSameDay(newStart, from.value)) {
    from.value = newStart
    to.value = newEnd
    await getTimeSheets()
  }
  if (!isSameDay(date, selectedDay.value)) {
    selectedDay.value = date
  }
}

async function nextWeek() {
  from.value = addWeeks(from.value, 1)
  to.value = addWeeks(to.value, 1)
  await getTimeSheets()
}

function prevArrow() {
  if (viewType.value === timeViewTypes.Weekly) {
    prevWeek()
    selectedDay.value = from.value
    return
  }
  const newDay = subDays(selectedDay.value, 1)
  selectedDay.value = newDay
  if (isBefore(newDay, from.value)) {
    prevWeek()
  }
}

function nextArrow() {
  if (viewType.value === timeViewTypes.Weekly) {
    nextWeek()
    selectedDay.value = from.value
    return
  }
  const newDay = addDays(selectedDay.value, 1)
  selectedDay.value = newDay
  if (isAfter(newDay, to.value)) {
    nextWeek()
  }
}

async function prevWeek() {
  from.value = subWeeks(from.value, 1)
  to.value = subWeeks(to.value, 1)
  await getTimeSheets()
}

const { userEmployeeId } = useAuth()
const selectedEmployee = ref(userEmployeeId.value)

const lastSelectedProject = useStorage('lastSelectedProject', null)
const lastSelectedTask = useStorage('lastSelectedTask', null)
async function getTimeSheets(employeeId?: string) {
  try {
    loading.value = true
    const fromDate = formatDate(from.value, 'yyyy-MM-dd')
    const toDate = formatDate(to.value, 'yyyy-MM-dd')
    const { data } = await axios.get('/restify/timesheets', {
      params: {
        sort: 'created_at',
        date: `${fromDate},${toDate}`,
        employee_id: employeeId || selectedEmployee.value,
        related: 'project[id|name],task[id|name]',
        perPage: 500,
      },
    })
    if (employeeId) {
      lastSelectedProject.value = null
      lastSelectedTask.value = null
    }
    timeLogs.value = data.map((time: Data<Timesheet>) => {
      time.attributes.initial_worked_minutes = time.attributes.worked_minutes
      return time
    })
  } finally {
    loading.value = false
  }
}

const employeeName = computed(() => {
  if (!selectedEmployee.value) {
    return
  }
  return employeeStore.getEmployeeNamesFromIds([selectedEmployee.value])
})

async function goToYourTimesheet() {
  if (!userEmployeeId.value) {
    return
  }
  selectedEmployee.value = userEmployeeId.value
  await getTimeSheets(userEmployeeId.value)
}

function onCreateTimeSheet(timeSheet: Data<Timesheet>) {
  timeLogs.value.push(timeSheet)
  showCreateDialog.value = false
}

function onEditTimeSheet(timeSheet: Data<Timesheet>) {
  const index = timeLogs.value.findIndex(t => t.id === timeSheet.id)
  timeLogs.value[index] = timeSheet
  showEditDialog.value = false
}

onMounted(async () => {
  await Promise.all([
    projectStore.getAllProjects(),
    getTimeSheets(),
  ])
})
</script>

<style lang="scss">
.time-logs {
  .employee-select .el-input {
    @apply bg-transparent w-full;
  }

  .el-date-editor .el-input__prefix-inner .el-input__icon {
    @apply mr-0;
  }

  .el-date-editor .el-input__inner {
    width: 0;
  }

  .el-date-editor .el-input__wrapper,
  .employee-select .el-input__wrapper {
    @apply rounded bg-transparent;
  }
  .day-column {
    min-width: max(8vw, 100px);
  }
}
</style>

<route lang="yaml">
name: Time
</route>
